

























































import tabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import gridWrapper from '@/components/wrappers/gridWrapper.vue'
import { ref, computed, reactive, defineComponent } from '@vue/composition-api'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import {
  CellKeyDownEvent,
  ColDef,
  GetContextMenuItemsParams,
  GridReadyEvent,
  MenuItemDef
} from 'ag-grid-community'
import { BooleanCellRenderer } from '@/lib/agGridColumnConfiguration'
import { GridKbShortCut } from '@/models/ExtraAgGridRelatedModels'
import { DocumentNode } from 'graphql'
import { QueryFilter } from '@/models/generated/graphql/ErpBackend.ts'

export default defineComponent({
  name: 'ApPrepayments',
  components: {
    'tab-bar': tabBarWrapper,
    'grid-wrapper': gridWrapper
  },
  setup () {
    const query: DocumentNode | string = ''
    const filters: QueryFilter[] = []
    const columns: Array<ColDef> = [
      { headerName: 'Vendor Code', field: 'client.short_name', colId: 'clientShortName' },
      { headerName: 'Vendor', field: 'client.name', colId: 'clientName' },
      { headerName: 'PT', field: 'transaction.attached_purchase.id', colId: 'pt' },
      { headerName: 'Date', field: 'paid_date', colId: 'paidDate', type: 'date' },
      { headerName: 'Amount Paid', field: 'amount_paid', colId: 'amountPaid', type: 'money' },
      { headerName: 'Balance', field: 'balance', colId: 'balance', type: 'money' },
      { headerName: 'Type', field: 'type', colId: 'type' },
      { headerName: 'Check No.', field: 'check_number', colId: 'checkNumber' },
      { headerName: 'Check Date', field: 'check_date', colId: 'checkDate', type: 'date' },
      { headerName: 'Reference', field: 'reference', colId: 'reference' },
      { headerName: 'Posted', field: 'posted', colId: 'posted', cellRenderer: BooleanCellRenderer },
      { headerName: 'Voided', field: 'voided', colId: 'voided', cellRenderer: BooleanCellRenderer },
      { headerName: 'Currency', field: 'currency', colId: 'currency' }
    ]

    function contextMenuItems (params: GetContextMenuItemsParams) {
      const contextMenu = []

      contextMenu.push(voidApPrepaymentItem(params))

      return contextMenu
    }

    // voiding contextMenuItem
    const canVoid = (params: GetContextMenuItemsParams | CellKeyDownEvent): boolean => {
      return params.api!.getSelectedNodes().every(n => {
        return n.data.status.id < 10 // TODO
      })
    }
    const voidApPrepaymentItem = (params: GetContextMenuItemsParams): MenuItemDef => {
      const canDoIt: boolean = canVoid(params)
      return {
        name: 'VOID',
        shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
        action: () => {
          prepareToVoidApPrepayments({ params: params })
        },
        disabled: canDoIt,
        tooltip: canDoIt ? '' : 'You cannot do this.',
        cssClasses: ['negativeAction']
      }
    }

    // voiding a prepayment
    const invoicesToVoid = ref<number[]>([])
    function prepareToVoidApPrepayments ({ params }: { params: GetContextMenuItemsParams | CellKeyDownEvent }) {
      for (const node of params.api!.getSelectedNodes()) {
        invoicesToVoid.value.push(node.data.id)
      }

      dialog.type = 'void'
      dialog.title = 'VOID Checks Confirmation'
    }
    // function voidApPrepayments () {
    //   apollo.mutate({
    //     mutation: '',
    //     variables: { payments: invoicesToVoid.value }
    //   })
    // }

    // adding a new prepayment transaction
    function prepareAddApPrepayment () {
      dialog.title = 'Enter details to add an AP Prepayment'
      dialog.type = 'add'
    }
    function addApPrepayment () {

    }

    // dialog
    const dialog: any = reactive({
      title: '',
      show: computed(() => dialog.type !== ''),
      component: computed(() => {
        const type = dialog.type
        switch (type) {
          case 'void':
            return 'void-ap-check'
          case 'add':
            return 'add-ap-check'
          case '':
            return ''
          default:
            return ''
        }
      }),
      type: ''
    })
    function resetDialog () {
      dialog.title = ''
      dialog.type = ''
    }

    // result and loading
    const result = reactive({
      success: false,
      fail: false
    })
    const loading = reactive({
      add: false,
      void: false
    })

    // kb actions
    const masterGridKbShortcuts: GridKbShortCut[] = [
      { key: 'v', handler: params => prepareToVoidApPrepayments({ params: params }), rules: params => canVoid(params), invalidMessage: () => 'You cannot do it.' }
    ]

    // ag-grid specific
    let gridApi
    let columnApi
    function gridReady (params: GridReadyEvent) {
      gridApi = ref(params.api)
      columnApi = ref(params.columnApi)
    }

    return {
      query,
      filters,
      columns,
      contextMenuItems,
      dialog,
      invoicesToVoid,
      prepareAddApPrepayment,
      resetDialog,
      result,
      loading,
      gridApi,
      columnApi,
      masterGridKbShortcuts,
      GridKbEventHandler,
      gridReady
    }
  }
})
